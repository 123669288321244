function initCookieBar() {
    var cookiebox   = document.querySelector('#cookie-bar'),
        body        = document.querySelector('body'),
        btn_akkoord = document.querySelector('#cookie-bar #cookie-bar-button');

    if (body.contains(cookiebox)) {
        btn_akkoord.addEventListener('click', akkoord);
    }

    function akkoord() {
        setCookie('cookiebar', 3, 365);

        document.querySelector('#cookie-bar').style.opacity = 0;
        document.querySelector('#cookie-bar').style.display = 'none';
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires     = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; domain=" + document.domain + "; " + expires + ";path=/";
    }
}

document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('#cookie-bar') !== null) {
        initCookieBar();
    }
});
